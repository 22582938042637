import React from "react";
import { Menu, Transition } from '@headlessui/react'
import { useIntl, changeLocale } from "gatsby-plugin-intl";

const printLanguage = (locale) => {
  if (locale === 'nl') {
    return 'Nederlands';
  }

  return 'International';
}

export const languages = [{
  locale: 'en',
  text: 'International (english)',
}, {
  locale: 'nl',
  text: 'Nederlands'
}]

function LanguageSelect() {
  const intl = useIntl()

  return (
    <div className="relative inline-block text-left m-4">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="flex rounded-full py-2 px-4 bg-gray-800 block flex-row align-center items-center cursor-pointer">
              <div className="text-white">
                {printLanguage(intl.locale)}
              </div>
              <svg
                className="h-4 w-4 ml-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </Menu.Button>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className="absolute right-0 w-40 bg-gray-800 mt-2 rounded-full shadow-lg z-10"
            >
              <Menu.Items
                className="origin-top-right divide-y divide-gray-100 outline-none"
              >
                <div className="py-1">
                  {languages.filter(({ locale }) => locale !== intl.locale).map(language => (
                    <Menu.Item key={language.locale}>
                      {() => (
                        <button
                          className={`text-white w-full px-4 py-2 text-xs leading-5 text-center`}
                          onClick={() => changeLocale(language.locale)}
                        >
                          {language.text}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default LanguageSelect;
