import React from "react";
import LanguageSelect from "./languageSelect";

function Header() {
  return (<div className="flex flex-row-reverse">
  <LanguageSelect />
  </div>);
}

export default Header;
