import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { languages } from "./languageSelect";
import { useIntl } from "react-intl";

function SEO({ description, lang, meta, keywords, title }) {
  const intl = useIntl();

  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: `https://describle.app/${lang}/preview/main.png`
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      {languages.map((language) => (
        <link rel="alternate" hrefLang="locale" href={`${site.siteMetadata.siteUrl}/${language.locale}`} key={language.locale} />
      ))}
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "Corporation",
          "name": "Describle",
          "description": "${intl.formatMessage({ id: 'meta_description' })}",
          "logo": "https://describle.app/icons/icon-512x512.png",
          "url": "https://describle.app",
          "email": "contact@describle.app",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Leonard Springerlaan 19",
            "addressLocality": "Groningen",
            "postalCode": "9727KB",
            "addressCountry": "Netherlands"
          }
        }`}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
