import PropTypes from "prop-types";
import React from "react";
import CookieConsent from "react-cookie-consent";

import Header from "../components/header";
import Footer from "../components/footer";
import { useIntl } from "react-intl";

function DefaultLayout({ children, header, footer }) {
  const intl = useIntl();
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-primary to-primary-accent">
      <CookieConsent
        buttonText={intl.formatMessage({ id: 'cookie_understand' })}
      >
        {intl.formatMessage({ id: 'cookie_info' })}
      </CookieConsent>

      {header && (<Header />)}
      <main className="flex-1 w-full">
        {children}
      </main>
      {footer && (<Footer/>)}
    </div>
  );
}

DefaultLayout.defaultProps = {
  footer: true,
  header: true,
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool.isRequired,
  header: PropTypes.bool.isRequired,
};

export default DefaultLayout;
