import React from "react";
import { useIntl } from "react-intl";
import { Link } from "gatsby-plugin-intl"

function Footer() {
  const intl = useIntl();

  return (
    <footer className="bg-gradient-to-r from-secondary-accent to-secondary">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <Link to="/privacy">
            Privacy Policy
          </Link>
          <span>
            |
          </span>
          <Link to="/terms">
            Terms & Conditions
          </Link>

        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-900">
            &copy; 2021 Describle | {intl.formatMessage({ id: 'all_rights_reserved'})}
          </p>
        </div>
      </div>

    </footer>
    );
}


export default Footer;
